import { render } from "./About.vue?vue&type=template&id=4e88bf29"
import script from "./About.vue?vue&type=script&lang=js"
export * from "./About.vue?vue&type=script&lang=js"

import "./About.vue?vue&type=style&index=0&id=4e88bf29&lang=css"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSeparator,QAvatar,QImg});
