<template>
  <div class="blockText">
    <div class="text-h1 blockTitle text-center text-white">
        Status
    </div>
    <div class="bg-white q-ma-lg mybox">
      <div class="fit row wrap justify-around items-start content-start">
        <div class="q-ma-lg" v-for="(legenda, i) in legendas" :key="i">
          <div class="fit column justify-center items-center content-center">
            <div>
              <q-rating
                v-model="legenda.level"
                size="1.5em"
                color="indigo-5"
                readonly
                :icon="fasJedi"
              />
            </div>
            <div >
              {{  legenda.nome  }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fit row wrap justify-around items-start content-start">
      <div class="col-md-6 col-sm-12">
        <Skills :icon="fasJedi" />    
      </div>
      <div class="col-md-6 col-sm-12">
        <Languages :icon="fasJedi" />
        <Tools :icon="fasJedi" />
      </div>
    </div>
  </div>
</template>

<script>
import { fasJedi } from '@quasar/extras/fontawesome-v5'
import Skills from '@/components/Skills.vue'
import Languages from '@/components/Languages.vue'
import Tools from '@/components/Tools.vue'

export default {
  name: 'Abilities',
  components: {
      Skills,
      Languages,
      Tools,
  },
  data() {
    return {
      fasJedi,
      legendas: [
        {
          nome: 'Force Sensitive',
          level: 1,
        },
        {
          nome: 'Jedi Youngling',
          level: 2,
        },
        {
          nome: 'Jedi Padawan',
          level: 3,
        },
        {
          nome: 'Jedi Knight',
          level: 4,
        },
        {
          nome: 'Jedi Master',
          level: 5,
        }
      ],
    }
  }
}
</script>

<style>

</style>