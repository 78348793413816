<template>
  <div>
    <div class="q-ma-lg">
      <q-table
        title="Ferramentas"
        :rows="rows"
        :columns="columns"
        row-key="name"
        :rows-per-page-options="[0]"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="level" :props="props">
                <q-rating
                  v-model="props.row.level"
                  size="1.5em"
                  color="indigo-5"
                  readonly
                  :icon="icon"
                />
            </q-td>
          </q-tr>
        </template>
      </q-table>  
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tools',
  props: [
      'icon'
  ],
  data() {
      return {
        columns: [
          {
            name: 'name',
            label: 'Ferramenta',
            align: 'right',
            field: 'name',
            sortable: true,
          },
          {
            name: 'level',
            label: 'Experiência',
            align: 'center',
            field: 'level',
            sortable: true,
          }
        ],
        rows: [
          {
            name: 'RSync (4 Anos)',
            level: 5
          },
          {
            name: 'RClone (2 Anos)',
            level: 5
          },
          {
            name: 'Dbeaver (4 anos)',
            level: 5
          },
          {
            name: 'VSCode (4 anos)',
            level: 4
          },
          {
            name: 'Linux (Debian) (7 anos)',
            level: 4
          },
          {
            name: 'Windows (10+ anos)',
            level: 5
          },
          {
            name: 'Inkscape (3+ anos)',
            level: 5
          },
          {
            name: 'Quasar (VueJS) (1 ano)',
            level: 3
          },
          {
            name: 'IPTables (3+ anos)',
            level: 4
          },
          {
            name: 'NFTables (2+ anos)',
            level: 3
          },
          {
            name: 'OpenVPN (4+ anos)',
            level: 4
          },
          {
            name: 'Samba',
            level: 4
          },
        ]
      }
  },
}
</script>

<style>

</style>