import { render } from "./HomeImg.vue?vue&type=template&id=14c4bb56"
import script from "./HomeImg.vue?vue&type=script&lang=js"
export * from "./HomeImg.vue?vue&type=script&lang=js"

import "./HomeImg.vue?vue&type=style&index=0&id=14c4bb56&lang=css"
script.render = render

export default script
import QImg from 'quasar/src/components/img/QImg.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QSeparator});
