<template>
  <div>
    <div class="q-ma-lg">
      <q-table
        :title="$q.screen.sm || $q.screen.xs ? '' : 'Linguas'"
        :grid="$q.screen.sm || $q.screen.xs"
        :rows="rows"
        :columns="columns"
        row-key="name"
        :hide-bottom="$q.screen.sm || $q.screen.xs"
        :rows-per-page-options="[0]"
      >
        <template v-if="!$q.screen.sm && !$q.screen.xs" v-slot:body="props">
          <q-tr :props="props">
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="level" :props="props">
                <q-rating
                  v-model="props.row.level"
                  size="1.5em"
                  color="indigo-5"
                  readonly
                  :icon="icon"
                />
            </q-td>
          </q-tr>
        </template>
        <template v-else v-slot:item="props">
          <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4 col-lg-3 grid-style-transition">
            <q-card :class="props.selected ? 'bg-grey-2' : ''">
              <q-list>
                <!-- <q-item v-for="col in props.cols.filter(col => col.name !== 'desc')" :key="col.name"> -->
                <q-item>
                  <q-item-section>
                    <q-item-label>{{ props.row.name }}</q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-rating
                      v-model="props.row.level"
                      size="1.5em"
                      color="indigo-5"
                      readonly
                      :icon="icon"
                    />
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card>
          </div>
        </template>
      </q-table>  
    </div>
  </div>
</template>

<script>
export default {
  name: 'Languages',
  props: [
      'icon'
  ],
  data() {
      return {
        columns: [
          {
            name: 'name',
            label: 'Língua',
            align: 'right',
            field: 'name',
            sortable: true,
          },
          {
            name: 'level',
            label: 'Conhecimento',
            align: 'center',
            field: 'level',
            sortable: true,
          }
        ],
        rows: [
          {
            name: 'Português (Língua Materna)',
            level: 5
          },
          {
            name: 'Inglês',
            level: 5
          },
          {
            name: 'Alemão (ich kann sprech deutch aber meine deutch ist sehr schlecht)',
            level: 1
          },
          {
            name: 'Francês (un petit garçon)',
            level: 0
          },
        ]
      }
  },
}
</script>

<style>

</style>