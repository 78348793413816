<template>
  <div :class="coverSmallDevice">
    <q-img
      img-class="homeimg"
      :ratio="16/9"
      src="@/assets/020f99955800d1a5a5e018ab3babcf59.jpg"
      position="60% 20%"
      height="100%"
      :fit="imageFit" >
      <div class="centertext absolute-full flex flex-center column">
        <div class="splashscreen text-weight-bold" :class="titleTextSize">
          MARCOS RODRIGO JUNG ALVES
        </div>
        <q-separator color="orange" inset />
        <div class="splashscreen text-h4 text-weight-light" :class="subtitleTextSize">
          Currículo Interativo
        </div>
      </div>
      <div class="splashscreen absolute-bottom text-subtitle1 text-center">
        Ps: Eu já era fan de Mandalorianos antes de ser <i><b>"Mainstream"</b></i>
      </div>
    </q-img>
  </div>
</template>

<script>
export default {
  name: 'HomeImg',
  computed: {
      imageFit() {
          if(this.$q.screen.sm || this.$q.screen.xs) {
              return 'none'
          } else {
              return 'fill'
          }
      },
      titleTextSize() {
          if(this.$q.screen.sm || this.$q.screen.xs) {
              return 'text-h4'
          } else {
              return 'text-h2'
          }
      },
      subtitleTextSize() {
          if(this.$q.screen.sm || this.$q.screen.xs) {
              return 'text-h5'
          } else {
              return 'text-h3'
          }
      },
      coverSmallDevice() {
          if(this.$q.screen.sm || this.$q.screen.xs) {
              return 'coverSmallDevices'
          } else {
              return ''
          }
      }

  }
}
</script>

<style>
  .splashscreen {
    font-family: 'Exo 2', sans-serif;
  }

  .homeimg {
      align-items: center;
  }

  .centertext {
    background: rgba(0,0,0,0.3) !important;
  }

  .coverSmallDevices {
      height: 56em;
  }
</style>