import { render } from "./Education.vue?vue&type=template&id=3b548d20"
import script from "./Education.vue?vue&type=script&lang=js"
export * from "./Education.vue?vue&type=script&lang=js"
script.render = render

export default script
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTimeline,QTimelineEntry});
