<template>
  <div class="blockText text-white q-ma-lg">
    <q-timeline layout="comfortable" side="right" color="secondary">
      <q-timeline-entry heading>Formações</q-timeline-entry>
      <q-timeline-entry v-for="(formacao, i) in formacoes" :key="i"
        :title="formacao.nome"
        :subtitle="formacao.ano"
        icon="school"
      >
        <div>
          {{ formacao.descricao }}
        </div>
      </q-timeline-entry>

      <q-timeline-entry heading>Cursos</q-timeline-entry>
      <q-timeline-entry v-for="(curso, i) in cursos" :key="i"
        :title="curso.nome"
        :subtitle="curso.ano"
        :icon="curso.icon"
      >
        <div>
          {{ curso.descricao }}
        </div>
      </q-timeline-entry>


    </q-timeline>
  </div>
</template>

<script>
export default {
  name: 'Education',
  data() {
    return {
      formacoes: [
        {
          nome: 'Pós Graduação em Gestão Pública',
          ano: '2017',
          descricao: 'Pós Graduação em Gestão Pública, na FAEL'
        },
        {
          nome: 'Bacharel em Sistemas de Informação',
          ano: '2013',
          descricao: 'Bacharel em Sistemas de Informação, pela UNOESC Campus Videira'
        },
      ],
      cursos: [
        {
          nome: 'VueJS - o Guia Completo',
          ano: '2021',
          descricao: 'Curso completo de VueJS, oferecido pela Cod3r',
          icon: 'desktop_windows'
        },
        {
          nome: 'Omnistack',
          ano: '2020',
          descricao: 'Curso Omnistack (NodeJS, ReactJs, ReactNative), da RocketSeat',
          icon: 'desktop_windows'
        },
        {
          nome: 'Fundamentos do Ethical Hacking',
          ano: '2019',
          descricao: 'Curso Prático, na Udemy, do Marcos Flávio Araújo Assunção (ainda a terminar)',
          icon: 'desktop_windows'
        },
        {
          nome: 'NodeJS - The Complete Guide',
          ano: '2019',
          descricao: 'The Complete Guide (MVC, REST APIs, GraphQL, Deno), na Udemy, do Maximilian Schwarzmüller',
          icon: 'desktop_windows'
        },
        {
          nome: 'Curso Adianti Framework',
          ano: '2017',
          descricao: 'Curso Completo - Dominando o Adianti Framework, da Adianti Solutions',
          icon: 'desktop_windows'
        },
        {
          nome: 'Inglês',
          ano: 'XXXX',
          descricao: 'Formação Completa "TN", do CCAA',
          icon: 'menu_book'
        },
      ],
    }
  }
}
</script>

<style>

</style>