<template>
  <div class="blockText">
    <div class="text-h1 blockTitle text-center text-white">
      Projetos
    </div>
    <div class="fit row wrap justify-around items-start content-start">
      <Project v-for="(projeto, i) in projetos" :key="i" :project="projeto"/>
    </div>
  </div>
</template>

<script>
import Project from '@/components/Project.vue'
export default {
  name: 'Projects',
  components: {
      Project
  },
  data() {
    return {
      projetos: [
          {
            nome: 'Portal Jaraguá5.0',
            local: 'Prefeitura Municipal de Jaraguá do Sul',
            descricao: 'Ambiente de desburocratização do processo de abertura e alteração de empresas.',
            link: 'https://www.jaraguadosul.sc.gov.br/peapp/',
            img: "https://i.ibb.co/kDXyMRq/jaragua50.png"
            // img: '@/assets/jaragua50.png'
          },
          {
            nome: 'Ambiente Sistemas',
            local: 'Prefeitura Municipal de Jaraguá do Sul',
            descricao: 'Ambiente de Sistemas Internos da PMJS. Desenvolvido para atender as demandas das diversas diretorias da prefeitura.',
            link: 'https://sistemas.jaraguadosul.sc.gov.br/',
            img: "https://i.ibb.co/kMMcFpg/sistemas.png"
          },
          {
            nome: 'MEUC Admin',
            local: 'Missão Evangélica União Cristã - MEUC Jaraguá do Sul',
            descricao: 'Sistema de gestão de Igreja Local. Cadastro de membros. Registro de atas e aprovação de atas. Cadastros diversos.',
            link: 'https://meucjaragua.com.br',
            img: "https://i.ibb.co/N7Xbw0x/meuc.png"
          },
          {
            nome: 'Variety Bible Verse Plugin',
            local: 'Pessoal',
            descricao: 'Plugin para mostrar Versículos Bíblicos no Variety.',
            link: 'https://github.com/marcosalves-bitg/variery_plugins',
            img: "https://i.ibb.co/hfGW9FR/variety-plugin.png"
          },
      ]
    }
  }
}
</script>

<style>

</style>