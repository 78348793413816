import { render } from "./Tools.vue?vue&type=template&id=06a865bc"
import script from "./Tools.vue?vue&type=script&lang=js"
export * from "./Tools.vue?vue&type=script&lang=js"
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTd,QRating});
