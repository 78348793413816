<template>
  <div>
    <div class="q-ma-lg">
      <q-table
        title="Habilidades"
        :rows="rows"
        :columns="columns"
        row-key="name"
        :rows-per-page-options="[0]"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="level" :props="props">
                <q-rating
                  v-model="props.row.level"
                  size="1.5em"
                  color="indigo-5"
                  readonly
                  :icon="icon"
                />
            </q-td>
          </q-tr>
        </template>
      </q-table>  
    </div>
  </div>
</template>

<script>
export default {
  name: 'Skills',
  props: [
      'icon'
  ],
  data() {
      return {
        columns: [
          {
            name: 'name',
            label: 'Habilidade',
            align: 'right',
            field: 'name',
            sortable: true,
          },
          {
            name: 'level',
            label: 'Experiência',
            align: 'center',
            field: 'level',
            sortable: true,
          }
        ],
        rows: [
          {
            name: 'MySQL',
            level: 5
          },
          {
            name: 'PostGreSQL',
            level: 4
          },
          {
            name: 'Sybase',
            level: 3
          },
          {
            name: 'JSON',
            level: 5
          },
          {
            name: 'BASH',
            level: 3
          },
          {
            name: 'CSS',
            level: 2
          },
          {
            name: 'PHP',
            level: 5
          },
          {
            name: 'Javascript',
            level: 4
          },
          {
            name: 'NodeJS',
            level: 5
          },
          {
            name: 'VueJS',
            level: 4
          },
          {
            name: 'Adianti Framework',
            level: 5
          },
          {
            name: 'Programação Orientada a Objeto',
            level: 5
          },
          {
            name: 'Scrum',
            level: 3
          },
          {
            name: 'BPMN',
            level: 3
          },
          {
            name: 'ReactJS',
            level: 2
          },
          {
            name: 'MVC',
            level: 3
          },
          {
            name: 'Kanban',
            level: 4
          },
          {
            name: 'Django',
            level: 2
          },
        ]
      }
  },
}
</script>

<style>

</style>