<template>
  <div class="blockText text-white q-ma-lg">
    <q-timeline layout="comfortable" color="secondary">
      <q-timeline-entry heading>Experiências Profissionais</q-timeline-entry>

      <q-timeline-entry v-for="(trabalho, trabalhoIndex) in trabalhos" :key="trabalhoIndex"
        :title="trabalho.nome"
        :subtitle="trabalho.ano"
        side="left"
      >
        <div v-for="(cargo, cargoIndex) in trabalho.cargos" :key="cargoIndex" style="margin-bottom: 10px">
          <div class="text-h5">
            {{ cargo.cargo }}
          </div>
            {{ cargo.descricao }}
        </div>
      </q-timeline-entry>
    </q-timeline>
  </div>
</template>

<script>
export default {
  name: 'Workexperience',
  data() {
    return {
      trabalhos: [
        {
          nome: 'Adapcon - Software e Inovação',
          ano: '2021 ~ Atual',
          cargos: [
            {
              cargo: '(Ago/2021 - Fev/2022) - Desenvolvedor Web Fullstack - Sênior',
              descricao: `Atuante em projetos de software, mais especificamente no time AMC. Desenvolvendo aplicações personalizadas e On-Demand
                          para o cliente, seguindo boas práticas de programação funcional e clean code, utilizando NodeJs (+ Serverless) para o Backend
                          e VueJS para o Frontend. Definições de novas tecnologias empregas no desenvolvimento de novas ferramentas. Experiência com DynamoDB
                          Amazon RDS, Lambda, AWS EC2 e demais ferramentas do ambiente AWS`
            },
            {
              cargo: '(Fev/2022 - Atual) - PO',
              descricao: `Atuante em projetos de software, mais especificamente no time AMC. Contato com o cliente, explorando as necessidades deste e propondo inovações.
                          Detalhamento de CARDS para os demais integrantes do time de desenvolvimento.
                          Estimativa de Prazos. Arquitetar soluções, utilizando-se das ferramentas disponíveis no ambiente AWS.
                          Gestão e plenejamento de Sprints e do Agile Team.`
            },
          ]
          
        },
        {
          nome: 'Prefeitura Municipal de Jaraguá do Sul',
          ano: '2014 ~ 2021',
          cargos: [
            {
              cargo: 'Analista de TI',
              descricao: `Instalação, Manutenção e Configuração de Servidores Linux e diversos serviços (WebServer NGINX; 
                          MariaDB, PostGre w/PostGis, Sybase; IPTables, NFTables; OpenVPN; RSync; RClone; Samba; etc);
                          Criação de shell scripts para automações (Backups, Monitoramento, etc);
                          Analise e Desenvolvimento de Sistemas (PHP, Python, Javascript - Adianti Framework, Django, VueJS);
                          Criação e Suporte a Apps QlikSense;
                          Administrador de Banco de Dados;
                          Experiência com SCM (Git, Mercurial)
                          Desenvolvimento de diversas ferramentas utilizadas internamente pela PMJS em PHP (Adianti Framework). 
                          Diretamente Responsável pelo ambiente "Portal do Empreendedor (Jaraguá 5.0)" da PMJS. Desenvolvimento de APIs da prefeitura, 
                          inclusive de Análise de Requisitos para desenvolvimento de novas aplicações. Sysadmin de algumas VMs internas da PMJS. "Self DevSecOps".`
            }
          ]
        },
        {
          nome: 'Transpézia',
          ano: '2013 ~ 2014',
          cargos: [
            {
              cargo: 'Auxiliar de Logística',
              descricao: `Gerenciamento de Rotas de Coletas, Criação de Sistema para controle de entrega de caçambas, automatizando e organizando o setor.`
            }
          ]
        },
        {
          nome: 'Cartório de Registro Civil',
          ano: '2013 ~ 2013',
          cargos: [
            {
              cargo: 'Escrivão Autorizado',
              descricao: `Escrevente de Registros Diversos do Cartório, Administrador de Banco de Dados MySQL.`
            }
          ]
        },
        {
          nome: 'Malharia JA',
          ano: '2012 ~ 2013',
          cargos: [
            {
              cargo: 'Gerente Geral',
              descricao: `Gerente de Contas a Pagar/Receber; Gerente de Produção - gestão da cadeia produtiva, compra de matéria prima, 
                          organização da linha de produção; Design gráfico - Criação de artes gráficas; Contato com clientes`
            }
          ]
        },
      ],
    }
  },
  methods: {
    timelineSide(index) {
      if(index === 0) return 'right'
      if(index % 2 === 0) return 'right'
      return 'left'
    }
  }
}
</script>

<style>

</style>