<template>
  <div class="q-my-lg">
    <div class="column justify-center">
      <div class="text-h1 blockTitle text-center text-white">
        Perfil
      </div>
      <div class="text-h5 blockText text-center text-indigo-2">
        Desenvolvedor FullStack - PHP + Javascript
      </div>
    </div>

    <div class="fit row wrap justify-around items-start content-center">
      <div class="col-md-4 col-sm-12">
        <q-card class="q-ma-lg bg-white ">
          <q-card-section>
            <div class="text-h4 blockText text-indigo-7">Sobre mim</div>
          </q-card-section>
          <q-separator inset />
          <q-card-section>
            <div class="text-h6 blockText">
                Programador FullStack sênior com mais de 10 anos de experiência no desenvolvimento 
                de softwares, aplicativos e sistemas WEB. Sysadmin, com ampla experiência em servidores
                Linux. Trabalho bem em equipe e tenho espírito de liderança. Aprendo rápido, sou despachado
                e procuro sempre automatizar os processos em que sou envolvido. 
            </div>
          </q-card-section>
        </q-card>
      </div>

      <div class="col-md-4 col-sm-12">
        <div class="q-ma-lg text-center">
          <q-avatar size="300px" color="white" class="shadow-15 ">
            <q-avatar size="280px">
              <q-img
                src="@/assets/profile.jpg">         
              </q-img>
            </q-avatar>
          </q-avatar>
        </div>
      </div>
      
      <div class="col-md-4 col-sm-12">
        <q-card class="q-ma-lg bg-white">
          <q-card-section>
            <div class="text-h4 blockText text-indigo-7">Detalhes</div>
          </q-card-section>
          <q-separator inset />
          <q-card-section>
            <div class="text-h5 blockText datailsTitle">Nome:</div>
            <div class="text-h6 blockText text-grey-8">Marcos Rodrigo Jung Alves:</div>
            <div class="text-h5 blockText datailsTitle">Idade:</div>
            <div class="text-h6 blockText text-grey-8">{{ idade }}</div>
            <div class="text-h5 blockText datailsTitle">Localização:</div>
            <div class="text-h6 blockText text-grey-8">Jaraguá do Sul/SC - Brasil - Mundão de Meu Deus</div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  computed: {
      idade() {
          return new Date(new Date() - new Date('1988-06-24')).getFullYear() - 1970;
      }
  }
}

</script>

<style>
  .datailsTitle {
      font-weight: 700;
  }
  .detailsText {
      font-weight: 400;
  }
</style>