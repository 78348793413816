<template>
  <div class="home">
    <HomeImg />
    <About id="about"/>
    <Timelines id="school"/>
    <Abilities id="stats"/>
    <Projects id="jobs"/>
    <Contact id="contact"/>
    <Menu />
  </div>
</template>

<script>
import HomeImg from '@/components/HomeImg.vue'
import About from '@/components/About.vue'
import Timelines from '@/components/Timelines.vue'
import Abilities from '@/components/Abilities.vue'
import Projects from '@/components/Projects.vue'
import Contact from '@/components/Contact.vue'
import Menu from '@/components/Menu.vue'

export default {
  name: 'Home',
  components: {
    HomeImg,
    About,
    Timelines,
    Abilities,
    Projects,
    Contact,
    Menu,
  }
}
</script>
