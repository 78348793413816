import { render } from "./Contact.vue?vue&type=template&id=617828a5"
import script from "./Contact.vue?vue&type=script&lang=js"
export * from "./Contact.vue?vue&type=script&lang=js"
script.render = render

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QTooltip});
