<template>
  <q-page-sticky position="top-right" :offset="fabPos">
    <q-fab
        glossy
        color="indigo-7"
        icon="menu"
        direction="down"
    >
      <q-fab-action color="indigo-4" @click="onClick('#about')" icon="person" />
      <q-fab-action color="indigo-4" @click="onClick('#school')" icon="school"/>
      <q-fab-action color="indigo-4" @click="onClick('#stats')" icon="bar_chart"/>
      <q-fab-action color="indigo-4" @click="onClick('#jobs')" icon="work"/>
      <q-fab-action color="indigo-4" @click="onClick('#contact')" icon="call"/>
    </q-fab>
  </q-page-sticky>
</template>

<script>

</script>

<style>

</style>