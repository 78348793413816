<template>
  <q-layout view="lHh Lpr lFf">
    <q-page-container>
      <Home/>
    </q-page-container>
  </q-layout>
</template>

<script>
import Home from './views/Home.vue'

export default {
  name: 'App',

  components: {
    Home
  },
}
</script>
<style>
body {
  text-decoration: none;
  background: linear-gradient(to right, #000428, #004e92); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-family: 'Exo 2', sans-serif;
}

.blockTitle {
  font-family: 'Caveat', cursive;
}

.blockText {
  font-family: 'Exo 2', sans-serif;
}
.mybox {
  border-radius: 10px;
}
</style>
