<template>
  <div class="blockText">
    <div class="text-h1 blockTitle text-center text-white">
      Contato
    </div>
    <div class="bg-white q-ma-lg mybox" style="height:300px;">
      <div class="text-h5 text-center q-pt-lg">
          Contate-me em qualquer uma das plataformas
      </div>
      <div class="fit row wrap justify-evenly  content-center">
        <div>
          <a href="https://www.instagram.com/jungalves/" target="_blank">
            <q-icon :name="fabInstagram" class="text-pink-4" style="font-size: 4rem;">
            </q-icon>
            <q-tooltip>
              Instagram pessoal
            </q-tooltip>
          </a>
        </div>
        <div>
          <a href="https://www.instagram.com/pipe.grep/" target="_blank">
            <q-icon :name="fabInstagram" class="text-pink-4" style="font-size: 4rem;">
            </q-icon>
            <q-tooltip>
              Instagram Profissional
            </q-tooltip>
          </a>
        </div>
        <div>
          <a href="https://pt-br.facebook.com/bigonizing/" target="_blank">
            <q-icon :name="fabFacebookSquare" class="text-primary" style="font-size: 4rem;">
            </q-icon>
            <q-tooltip>
              Instagram pessoal
            </q-tooltip>
          </a>
        </div>
        <div>
          <a href="https://www.linkedin.com/in/marcos-rodrigo-jung-alves-955503185/" target="_blank">
            <q-icon :name="fabLinkedin" class="text-primary" style="font-size: 4rem;">
            </q-icon>
            <q-tooltip>
              Linkedin
            </q-tooltip>
          </a>
        </div>
        <div>
          <a href="mailto:digosw@gmail.com" target="_blank">
            <q-icon :name="fasEnvelopeSquare" class="text-red-6" style="font-size: 4rem;">
            </q-icon>
            <q-tooltip>
              E-mail
            </q-tooltip>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fabInstagram } from '@quasar/extras/fontawesome-v5'
import { fabFacebookSquare } from '@quasar/extras/fontawesome-v5'
import { fabLinkedin } from '@quasar/extras/fontawesome-v5'
import { fasEnvelopeSquare } from '@quasar/extras/fontawesome-v5'


export default {
  name: 'Contact',
  components: {
  },
  data() {
    return {
      fabInstagram,
      fabFacebookSquare,
      fabLinkedin,
      fasEnvelopeSquare,
      contatos: [
         {
           nome: 'instagram pessoal',
           icone: 'fabInstagram',
           link: 'https://www.instagram.com/jungalves/',
         },
         {
           nome: 'instagram profissional',
           icone: this.fabInstagram,
           link: 'https://www.instagram.com/pipe.grep/',
         },
         {
           nome: 'facebook',
           icone: this.fabFacebookSquare,
           link: 'https://pt-br.facebook.com/bigonizing/',
         },
         {
           nome: 'linkedin',
           icone: this.fabLinkedin,
           link: 'https://www.linkedin.com/in/marcos-rodrigo-jung-alves-955503185/',
         },
         {
           nome: 'email',
           icone: this.fasEnvelopeSquare,
           link: 'mailto:digosw@gmail.com',
         }
      ]
    }
  }
}
</script>

<style>

</style>