import { render } from "./Project.vue?vue&type=template&id=5c3b9cb3"
import script from "./Project.vue?vue&type=script&lang=js"
export * from "./Project.vue?vue&type=script&lang=js"

import "./Project.vue?vue&type=style&index=0&id=5c3b9cb3&lang=css"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QImg,QCardSection});
