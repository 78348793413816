<template>
  <div class="q-pa-md row items-center q-gutter-md">
    <a :href="project.link">
      <q-card class="my-card" style="width:400px;">
        <q-img :src="project.img"/>
        <q-card-section>
          <div class="text-h6">{{ project.nome }}</div>
          <div class="text-subtitle2">{{ project.local }}</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          {{ project.descricao }}
        </q-card-section>
      </q-card>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Project',
  props: [
    'project'
  ],
}
</script>

<style>
a {
  text-decoration: none;
}
</style>