<template>
  <div>
    <div class="text-h1 blockTitle text-center text-white">
      Experiência
    </div>
    <Education />
    <Workexperience />
  </div>
</template>

<script>
import Workexperience from '@/components/Workexperience.vue'
import Education from '@/components/Education.vue'

export default {
  name:"Timelines",
  components: {
    Workexperience,
    Education,
  }
}
</script>

<style>

</style>